import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import { Link } from "gatsby"
import cbtSessionsStyles from "./cbt-sessions.module.scss"

const CBTSessionsPage = () => {
  return (
    <Layout isHome={false}>
      <Head
        title="CBT Sessions"
        description="Accessible online counselling with a sliding scale price. Combines Cognitive Behavioural Therapy (CBT) with Gestalt, feminism, and other therapeutic approaches."
      />
      <h2>Integrative CBT and My Approach</h2>
      <section>
        <h3 className={cbtSessionsStyles.subtitle}>
          Cognitive Behavioural Therapy (CBT)
        </h3>
        <p>
          Cognitive Behavioural Therapy (CBT) is an approach designed to develop
          new ways of thinking about ourselves and our environments. It helps us
          to learn that we are not our thoughts, and about how our thoughts,
          emotions, and behaviours interact. CBT tools help to identify strongly
          held, often unconscious, beliefs and coping strategies developed
          earlier in life, which can now be re-evaluated.
        </p>
        <blockquote className={cbtSessionsStyles.quoteBlock}>
          <p>
            <cite>
              Therapy is not about being perfect or always happy, nor is it
              about never feeling sad or anxious. It is about creating an
              environment where you feel unconditionally accepted and you can
              practice that unconditional acceptance towards yourself.
            </cite>
          </p>
        </blockquote>
        <p className={cbtSessionsStyles.endSectionPara}>
          CBT therapy involves exploring together what your values are,
          committing to practising how to live more in line with them, and
          negotiating the obstacles (internal and external) that get in your
          way. Your personal goals direct the course of therapy and these goals
          often evolve as the process unfolds.
        </p>
      </section>
      <section>
        <h3 className={cbtSessionsStyles.subtitle}>
          My integrative CBT Approach
        </h3>
        <p>
          I call my approach integrative CBT because as well as CBT I also use
          tools from other approaches, such as Acceptance Commitment Therapy
          (ACT), Internal Family Systems therapy, Gestalt, Non-Violent
          Communication (NVC), and what I have learned about power, privilege,
          and oppression from years of involvement in movements for social
          justice.
        </p>
        <blockquote className={cbtSessionsStyles.quoteBlock}>
          <p>
            <q>
              <cite>
                To be yourself in a world that is constantly trying to make you
                something else is the greatest accomplishment.
              </cite>
            </q>{" "}
            <span className={cbtSessionsStyles.quoteAuthor}>
              Ralph Waldo Emerson
            </span>
          </p>
        </blockquote>
        <p>
          It is also crucial to keep in mind most studies into the effectiveness
          of therapy for various issues find that{" "}
          <span className={cbtSessionsStyles.emphasisInText}>
            the quality of the relationship between therapist and client is the
            most significant factor,
          </span>{" "}
          beyond any differences between different therapeutic approaches.
          <sup>
            <a
              className={cbtSessionsStyles.citationSuperscript}
              href="#ardito-raballino-2011"
            >
              1
            </a>
          </sup>{" "}
          Therefore, looking for the right match in personality, style, values
          and that gut feeling that someone ‘just gets you’ is more important
          than their therapeutic background. My{" "}
          <Link to="/about-me/">About Me</Link> page includes my video
          introduction, which may help you get a sense of whether I am someone
          you think you could connect with.
        </p>
      </section>
      <section>
        <div className={cbtSessionsStyles.cardWithShadow}>
          <div className={cbtSessionsStyles.cardTextContainer}>
            <h3 className={cbtSessionsStyles.subtitle}>
              Session Fees & Practicalities
            </h3>
            <p>
              The standard fee for a 55 minute session with me is £50. Financial
              accessibility is important to me, so I do operate a sliding scale.
              I trust people’s assessment of their financial capacity and
              welcome discussion of fees in our introductory phone call.
            </p>
            <p>
              All of my sessions are online. My preferred platform is using Zoom
              but we can talk about other platforms if Zoom is not suitable for
              you.
            </p>
            <p>
              There is more info about CBT and my personal approach in the{" "}
              <Link to="/faq/">FAQ page</Link> and my{" "}
              <Link to="/blog/">blog</Link>. If you have more questions or want
              to talk about how we can work together, visit the{" "}
              <Link to="/contact-me/">Contact Me</Link> page.
            </p>
          </div>
          <div className={cbtSessionsStyles.cardImageContainer}>
            <img
              className={cbtSessionsStyles.cardImage}
              src="/gavin_lashley_therapy_portrait_rz2.jpg"
              alt="Gavin Lashley portrait"
            />
          </div>
        </div>
      </section>
      <div className={cbtSessionsStyles.footNote}>
        <a
          name="ardito-raballino-2011"
          href="https://doi.org/10.3389/fpsyg.2011.00270"
          target="_blank"
          rel="noreferrer noopener"
          className={cbtSessionsStyles.footNoteLink}
        >
          1. Ardito & Raballino, 2011 https://doi.org/10.3389/fpsyg.2011.00270
        </a>
      </div>
    </Layout>
  )
}

export default CBTSessionsPage
